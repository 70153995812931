<template>
  <div class="bg-white sm:bg-main-bg min-h-full h-screen pb-12">
    <div class="px-4 lg:px-0 font-medium h-24 flex flex-col justify-center">
      <div class="max-w-4xl mx-auto w-full">
        <a href="/" class="cursor-pointer">
          <img src="/assets/images/LoanSpot-logo.svg" />
        </a>
      </div>
    </div>
    <div class="max-w-md mx-auto px-4 md:px-0 mt-10">
      <div
        class="px-0 max-w-md sm:border border-border bg-white sm:py-8 sm:px-6 rounded-lg mx-auto"
      >
        <h3 class="font-medium text-3x1 login--title text-black">
          Email Verified
        </h3>
        <p class="pt-6 pb-16 font-medium text-sm text-text md:w-fulls">
          Your email has been verified.
          <br />You can continue to apply for loans by clicking the button to
          your Dashboard.
        </p>
        <span
          id="back-btn"
          @click.prevent="goToDashboard"
          class="bg-main-bg border cursor-pointer border-input w-full rounded-md h-12 text-primary font-medium text-sm flex items-center justify-center tracking-wide"
        >
          Go to Dashboard
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifiedEmail',
  methods: {
    goToDashboard() {
      window.location.replace('/')
    }
  }
};
</script>

<style lang="scss" scoped>
.login--title {
  font-family: SohneHalbfett, sans-serif;
  font-size: 1.5rem;
}
</style>

